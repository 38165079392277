import React, { FC, memo } from 'react'
import Link from 'next/link'
import Logo from '../svg/logo.svg'

const Footer: FC = () => {
  const year = new Date().getFullYear()

  return (
    <footer className='bg-grayscale-50 text-white-0 pt-6 lg:pt-12'>
      <div className='container xl:container-xl px-6 xl:px-0'>
        <div className='flex flex-col lg:flex-row border-b-1 border-grayscale-100 lg:pb-10 pb-6'>
          <Link href='/'>
            <Logo className='block lg:w-18 w-13' />
          </Link>
          <p className='lg:ml-10 mt-5 lg:mt-0 text-grayscale-250 lg:w-107'>
            Fera – финтех проект, который позволяет малому и среднему бизнесу по
            всей России приобретать различное оборудование в лизинг.{' '}
          </p>
        </div>
        {/* <div className='flex flex-col lg:flex-row lg:py-10 py-6 border-b-1 border-grayscale-100'>
					<div className='mr-20 lg:mb-0 mb-5'>
						<h3 className='font-display text-h500 lg:text-h400 font-medium mb-4'>Информация</h3>
						<div className='grid gap-y-3 lg:gap-y-2'>
							<a className='hover:underline' href='#'>О компании</a>
							<a className='hover:underline' href='#'>Частые вопросы</a>
							<a className='hover:underline' href='#'>Стать партнёром</a>
							<a className='hover:underline' href='#'>Поставщики</a>
						</div>
					</div>
					<div>
						<h3 className='font-display text-h500 lg:text-h400 font-medium mb-4'>Лизинг</h3>
						<div className='grid gap-y-3 lg:gap-y-2 gap-x-12 auto-cols-max grid-cols-1 md:grid-cols-auto-2 lg:grid-cols-auto-3'>
							<a className='hover:underline' href='#'>Оборудование для ресторанного бизнеса</a>
							<a className='hover:underline' href='#'>Оборудование для офиса</a>
							<a className='hover:underline' href='#'>Оборудование для производства</a>
							<a className='hover:underline' href='#'>Оборудование для фитнес клубов</a>

							<a className='hover:underline' href='#'>Медицинское оборудование</a>
							<a className='hover:underline' href='#'>Торгово-складское оборудование</a>
							<a className='hover:underline' href='#'>Оборудование для автосервисов</a>
							<a className='hover:underline' href='#'>Самокаты (СИМ)</a>

							<a className='hover:underline' href='#'>Клининг оборудование</a>
							<a className='hover:underline' href='#'>Рекламное оборудование</a>
							<a className='hover:underline' href='#'>Промышленное оборудование</a>
						</div>
					</div>
				</div> */}
        <div className='flex flex-col xl:flex-row justify-between lg:py-10 py-6 border-b-1 border-grayscale-100'>
          <div className='flex flex-col md:flex-row'>
            <div className='flex justify-start md:justify-between items-center md:mr-10 mb-5 xl:mb-0'>
              <svg
                width='35'
                height='35'
                viewBox='0 0 35 35'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12.4788 5.37686C19.174 2.60311 26.8492 5.78374 29.623 12.4789C32.3967 19.1742 29.2161 26.8494 22.5209 29.6231C15.8257 32.3969 8.15049 29.2162 5.37674 22.521C2.60445 15.8258 5.78362 8.15061 12.4788 5.37686'
                  stroke='#A6A6A6'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M17.0889 11.645V18.4277L22.4205 21.6784'
                  stroke='#A6A6A6'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <div className='ml-4'>
                <div className='text-grayscale-250'>Режим работы</div>
                <div className='font-display text-h400 font-medium'>
                  10:00 - 19:00
                </div>
              </div>
            </div>

            <div className='flex justify-start lg:justify-between items-center md:mr-10 mb-5 xl:mb-0'>
              <svg
                width='35'
                height='35'
                viewBox='0 0 35 35'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M23.3333 30.625H11.6667C10.0552 30.625 8.75 29.3198 8.75 27.7083V7.29167C8.75 5.68021 10.0552 4.375 11.6667 4.375H23.3333C24.9448 4.375 26.25 5.68021 26.25 7.29167V27.7083C26.25 29.3198 24.9448 30.625 23.3333 30.625Z'
                  stroke='#A6A6A6'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M17.494 24C16.666 24 15.994 24.672 16 25.5C16 26.328 16.672 27 17.5 27C18.328 27 19 26.328 19 25.5C19 24.672 18.328 24 17.494 24Z'
                  fill='#A6A6A6'
                />
                <path
                  d='M15.6768 8.75H19.3226'
                  stroke='#A6A6A6'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <div className='ml-4'>
                <div className='text-grayscale-250'>Номер телефона</div>
                <a
                  href='tel:88001002096'
                  className='font-display text-h400 font-medium'
                >
                  8 800 100-20-96
                </a>
              </div>
            </div>

            <div className='flex justify-start lg:justify-between items-center lg:mr-10 mb-5 xl:mb-0'>
              <svg
                width='35'
                height='35'
                viewBox='0 0 35 35'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M27.7083 27.6806H7.29167C5.68021 27.6806 4.375 26.3754 4.375 24.7639V10.236C4.375 8.62454 5.68021 7.31934 7.29167 7.31934H27.7083C29.3198 7.31934 30.625 8.62454 30.625 10.236V24.7654C30.625 26.3754 29.3198 27.6806 27.7083 27.6806V27.6806Z'
                  stroke='#A6A6A6'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M24.7913 13.125L17.4997 17.5L10.208 13.125'
                  stroke='#A6A6A6'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <div className='ml-4'>
                <div className='text-grayscale-250'>Электронная почта</div>
                <a
                  href='mailto:info@fera.ru'
                  className='font-display text-h400 font-medium'
                >
                  info@fera.ru
                </a>
              </div>
            </div>
          </div>

          <div className='flex'>
            <a
              href='https://t.me/+79257831590'
              target='_blank'
              rel='noreferrer'
              className='md:px-6 flex-grow md:flex-none h-9 rounded-full border-grayscale-150 hover:border-grayscale-250 border-1 flex items-center justify-center mr-2'
            >
              <svg
                width='18'
                height='19'
                viewBox='0 0 18 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.42045 8.70083L11.3549 5.01971C12.2368 4.63628 15.2278 3.40928 15.2278 3.40928C15.2278 3.40928 16.6083 2.87251 16.4932 4.17613C16.4548 4.7129 16.1482 6.59188 15.8413 8.62399L14.8828 14.644C14.8828 14.644 14.806 15.526 14.1531 15.6793C13.5001 15.8327 12.4275 15.1425 12.2359 14.9891C12.0826 14.8741 9.36002 13.1486 8.36298 12.305C8.09454 12.075 7.78784 11.6148 8.40135 11.078C9.78181 9.81263 11.4307 8.24045 12.4275 7.24353C12.8877 6.78337 13.3478 5.70972 11.4306 7.01356L6.02396 10.6563C6.02396 10.6563 5.41034 11.0397 4.26007 10.6947C3.10979 10.3496 1.7677 9.88947 1.7677 9.88947C1.7677 9.88947 0.847387 9.31433 2.41956 8.70094L2.42045 8.70083Z'
                  fill='white'
                />
              </svg>
              <div className='text-p300 font-semibold ml-2'>Telegram</div>
            </a>
            <a
              href='https://wa.me/79257831590'
              target='_blank'
              rel='noreferrer'
              className='md:px-6 flex-grow md:flex-none h-9 rounded-full border-grayscale-150 hover:border-grayscale-250 border-1 flex items-center justify-center'
            >
              <svg
                width='19'
                height='19'
                viewBox='0 0 19 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M15.3144 4.18052C13.9044 2.77578 12.0292 2.00183 10.0313 2.00098C5.91469 2.00098 2.56424 5.33465 2.56259 9.43219C2.56209 10.742 2.90593 12.0204 3.55955 13.1475L2.5 16.9985L6.45923 15.9651C7.55011 16.5572 8.77831 16.8693 10.0283 16.8697H10.0314C14.1475 16.8697 17.4983 13.5356 17.5 9.43798C17.5007 7.45235 16.7245 5.5852 15.3144 4.18052ZM10.0314 15.6146H10.0289C8.91498 15.6142 7.82245 15.3164 6.8694 14.7536L6.64269 14.6198L4.29322 15.233L4.92032 12.9536L4.77272 12.7198C4.15137 11.7364 3.82312 10.5998 3.82362 9.43255C3.82499 6.02687 6.6097 3.25614 10.0339 3.25614C11.6919 3.25671 13.2505 3.90002 14.4225 5.06766C15.5945 6.23523 16.2395 7.78712 16.2389 9.43762C16.2375 12.8436 13.4528 15.6146 10.0314 15.6146ZM13.4364 10.9883C13.2497 10.8954 12.3323 10.4462 12.1612 10.3842C11.9902 10.3223 11.8658 10.2913 11.7414 10.4772C11.617 10.6631 11.2593 11.0813 11.1504 11.2053C11.0415 11.3292 10.9327 11.3447 10.7461 11.2517C10.5595 11.1588 9.95821 10.9627 9.24538 10.33C8.69064 9.83768 8.31612 9.22962 8.20725 9.04364C8.09839 8.85773 8.19561 8.75727 8.2891 8.66468C8.37303 8.58145 8.47571 8.44774 8.56906 8.33934C8.6624 8.23094 8.69344 8.15343 8.75567 8.02952C8.8179 7.9056 8.78678 7.79713 8.74008 7.70425C8.69344 7.61123 8.32021 6.69721 8.16471 6.32539C8.01323 5.96337 7.85939 6.01235 7.74484 6.00663C7.63612 6.00127 7.51159 6.00005 7.38714 6.00005C7.26275 6.00005 7.06061 6.04653 6.88952 6.23244C6.7185 6.41835 6.2364 6.8676 6.2364 7.78162C6.2364 8.69564 6.90504 9.57855 6.99838 9.70254C7.09165 9.82645 8.31432 11.702 10.1863 12.5064C10.6315 12.6978 10.9791 12.812 11.2501 12.8976C11.6972 13.039 12.104 13.019 12.4255 12.9712C12.7841 12.9179 13.5296 12.522 13.6851 12.0882C13.8406 11.6544 13.8406 11.2826 13.794 11.2052C13.7474 11.1278 13.623 11.0813 13.4364 10.9883Z'
                  fill='white'
                />
              </svg>
              <div className='text-p300 font-semibold ml-2'>WhatsApp</div>
            </a>
          </div>
        </div>

        <div className='flex lg:flex-row flex-col justify-between lg:py-10 pt-6 pb-12 text-p450 text-grayscale-250'>
          <div className='flex flex-col lg:mb-0 mb-6'>
            <p className='lg:mb-0 mb-5'>
              Этот сайт использует файлы cookie 🍪
              <br />
              <br />
              Информация на сайте не является публичной офертой
            </p>
            <div className='flex lg:flex-row flex-col text-p400 font-medium text-white-0 mt-auto'>
              <a
                href='/docs/personal-data-policy.pdf'
                target='_blank'
                rel='noreferrer'
                className='lg:mr-6 lg:mb-0 mb-5 hover:underline'
              >
                Политика обработки персональных данных
              </a>
              <a
                href='https://zettains.ru/upload/rules/Пр41%20-%2002.02.2015%20-%20ИОГ_Правила.pdf'
                target='_blank'
                rel='noreferrer'
                className='lg:mr-6 lg:mb-0 hover:underline'
              >
                Условия страхования
              </a>
            </div>
          </div>

          <div className='text-left lg:text-right'>
            <p>ООО «Фера»</p>
            <p>ИНН 9703051608</p>
            <p>г. Москва, Пресненская набережная, 12</p>
            <p className='mt-5 lg:mt-11'>Copyright © {year} Fera LLC</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default memo(Footer)
